.table {
    border: green solid 5px;
    margin: 5px;
    border-radius: 10px;
}

#title {
    text-align: center;
    color: #000;
}
#titledate {
    text-align: right;
    color: black;
}

#surveys {
    color: black;
    text-align: center;
    font-size: 0.8em;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
}

#surveys td, #surveys th {
    border: 1px solid #ddd;
    padding: 8px;
    max-width: 20px;
}

#surveys tr:nth-child(even){background-color: #f2f2f2;}

#surveys tr:hover {background-color: #ddd;}

#surveys th {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 0.8em;
    text-align: left;
    background-color: green;
    color: white;
    height: auto;
    width: 30px;
}

#text-rotate {
    transform: rotate(-85deg);
}

.date{
    margin: 15px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.date-picker{
    /* left: 50%;  */
    /* margin: 0 auto; */
    font-size: 1.5em;
    padding: 5px;
    width: 65%;
    margin-top: 5px;
    margin-left:5%;
    margin-right:8%;
    text-align: center;
    font-weight: bold;
    border-radius: 8px;
    background-color: #48A09D;
    color: white;
    border: 2px solid #84C09F;
        /* align-items: center !important; */
}

.message{
    border-width: 0px;
    text-align: center;
}

.right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 30px;
}

.width120 {
    min-width: 120px;
    text-align: left;
}

.width50 {
    min-width: 50px;
    text-align: left;
}

.outLine {
    text-shadow: -1px 1px 0 #000,
                1px 1px 0 #000,
                1px -1px 0 #000,
                -1px -1px 0 #000;
}


/* ************************ MEDIA SCREENS ************************************ */

@media screen and (max-width: 1200px) {
    #surveys th {
        font-size: 0.6em;
    }
    .font-size{
        font-size: 0.6em; 
    }
}

@media screen and (max-width: 900px) {
    #surveys th {
        font-size: 0.5em;
    }
    .font-size{
        font-size: 0.5em;  
    }
}


@media screen and (max-width: 600px) {
    #surveys th {
        font-size: 0.4em;
    }
    .font-size{
        font-size: 0.4em;  
    }
}