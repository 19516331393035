
*{
  font-family: arial, helvetica, verdana, "Yu Gothic", sans-serif;
  margin: 0;
}

html {
  --color-text: black;
  --color-background: green;
  --color-white: white;
  --color-primary: white;
  --color-list-even: #84C09F;
  --color-list-odd: white;
  --color-secondary: greenyellow;
  
  /* Branding Design Palette */
  --blue-1: #0E837F;
  --blue-2: #0e6c83;
  --green-1: #008000;
  --green-2: #239555;
  --green-3: #84C09F;
  
  --font-size-small: 16px;
  --font-size-medium: 22px;
  --gutter: 16px;
  @media (min-width: 1024px) {
    --font-size-small: 21px;
    --font-size-medium: 24px;
    --gutter: 12px;
  }
}

.app{
  width: 100%;
}

body{
  color: var(--color-white);
  background: var(--color-white);
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li{
  margin: 2px 15px 5px 30px;
}

p{
  margin: 2px 15px 5px 15px;
  white-space:pre-wrap;
}

.margin-sides{
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

.wrapper{
  width: 100%;
  margin: 0 auto;
}

.containerFlex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.containerBlock{
  display: block;
  margin: 2px auto;
  width: 100%;
  text-align: center;
}

.filler{
  flex: 1;
  /* background-color: aqua;   */
  width: 100%;
}

.flexGrow{
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
}

/* ******************** Navbar ************************ */


.navLink2 {
  display: flex;
  margin-top: 1px;
  height: 60px;
}

a:hover{
  color: white;
}


/* ******************** Title ************************ */
.main-banner {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 90px;
}

/* ******************** Main Content ************************ */

.homeWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 50px;
}

.phone1 {
  height: 464px;
  width: 218px;
}

.phone2 {
  height: 448px;
  width: 217px;
}

.main-wrapper{
  padding: 10px 5px;
}

.main{
  flex: 5;
  background: var(--green-2);
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  border-radius: 20px;
  padding: 15px;
  padding-bottom: 20px;
  margin: 10px;
  border: 2px solid white;
}

.main-border{
  flex: 5;
  background: var(--green-3);
  text-align: center;
  margin-bottom: 1;
  max-width: 600px;
  border-radius: 30px;
  border: 12px solid var(--blue-1);
  margin: 10px;
  margin-bottom: 60px;
}

.main li {
  padding: 10px;
}

.description{
  display: block;
  color: #fff;
  font-size: 14px;
}

.list-left{
  text-align: left;
}

.list-left li{
  margin: 1px;
  padding: 1px;
  padding-left: 30px;
}

/* ******************** Footer ************************ */
.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--green-1);
  height: 50px;
  width: '100%';
}

.footerLink{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
}

.foot-wrapper{
  display: flex;
  width: 100%;
  text-align: center;
  background: #008000;
}

.linksTo {
  display: flex;
  flex-wrap: wrap;
  flex: 6;
  justify-content: space-around;
  align-items: center;
}

footer li{
  list-style-type: none;
  text-align: centre;
  display: inline;
}

footer a{
  text-decoration: none;
  text-align: center;
  color: #fff;
}

/* ******************** form Content ************************ */
.form{
  display: block;
  align-items: center;
  margin: auto;
  width: 80%;
  /* background-color: tomato; */
}

.input-field{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-basis: 80%;
  border-width: 1px;
  border-radius: 6px;
  border-color: blue;
  line-height: 1.5rem;
  padding: 10px 20px;
  margin: 6px;
  color: white;
  /* box-sizing: border-box; */
  justify-content: space-between;
  /* background-color: rebeccapurple; */
}

.input-field label {
  flex: 2 1 30%;
  align-items: flex-start;
  min-width: 20%;
}

.input-field input {
  flex: 5 1 50%;
  border-radius: 6px;
  align-items: flex-start;
}

.input-field:focus {
  outline: none;
  background-color: #ccc;
}

.Invalid  input {
  border: 1px solid red;
  background-color: #FDA49A;
}

.radioTop{
  margin-top: 10px;
  box-shadow: 2px 2px 2px 2px white;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

/* ******************** buttons ************************ */

.btn-containerFlex{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btnPrimary{
  display: block;
  border: 2px ridge white;
  border-radius: 8px; 
  width: 100px;
  padding: 12px;
  margin-top: 15px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  text-align: center;
  border-collapse: separate;
  background: linear-gradient(to top, #0a5082 0%, #0E837F 100%);
  background: -moz-linear-gradient(to top, #0a5082 0%, #0E837F 100%);
  background: -webkit-linear-gradient(to top, #0a5082 0%, #0E837F 100%);
  box-shadow: 2px 2px 4px 2px rgba(23, 56, 52, 0.6);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.btnText{
  display: block;
  border: 0.2px solid white;
  border-radius: 8px; 
  width: 100px;
  padding: 12px;
  margin-top: 15px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  text-align: center;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.btn{
  display: block;
  border: 0.2px solid #77aaaa;
  border-radius: 8px; 
  width: 100px;
  padding: 12px;
  margin-top: 15px;
  font-weight: bold;
  font-size: 14px;
  color: white;
  text-align: center;
  border-collapse: separate;
  background-color: #013220;
  background: linear-gradient(to top, #013220 0%, green 100%);
  background: -moz-linear-gradient(to top, #013220 0%, green 100%);
  background: -webkit-linear-gradient(to top, #013220 0%, green 100%);
  box-shadow: 2px 2px 4px 2px rgba(23, 56, 52, 0.6);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn:hover{
  border-radius: 8px; 
  background-color: green;
  background: linear-gradient(to bottom, #013220 0%, green 100%);
  background: -moz-linear-gradient(to bottom, #013220 0%, green 100%);
  background: -webkit-linear-gradient(to bottom, #013220 0%, green 100%);
}
.btn:disabled {
  cursor: not-allowed;
  pointer-events: none;
  color:rgb(150, 150, 150);
}
.btnPrimary:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

/* ******************** all Users ************************ */

.list-wrapper{
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
}

.user-items{
  display: flex;
}

.list-wrapper:nth-child(even){
  background-color:var(--color-list-even);
}

.list-wrapper:nth-child(odd){
  background-color:var(--color-list-odd);
}

#user-name :hover{
  color: rgb(200, 0, 255);
}

.user-name{
  flex: 1;
  font-weight: bold;
  color: rgb(0, 39, 167);
}

#user-name{
  flex-grow: 8;
  flex-wrap: nowrap;
  text-align: left;
  max-width: 80%;
  padding: 0;
  margin: 5px;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
}
.user-column{
  display: flex;
}

.user-survey{
  flex-grow: 1;
  width: 30px;
  text-align: left;
  color: var(--color-text);
}
.vertical-center img{
  align-items: center;
  mix-blend-mode: multiply;
  min-width: 10px;
  cursor: pointer;
}
.vertical-center {
  flex: 1;
  justify-content: center;
  padding-top: 7px;
  padding-right: 5px;
}
.h2{
  margin: 10px;
  font-size: 1.8em ;
}
.h3{
  margin: 15px;
  font-size: 2em ;
}
.h4{
  margin: 15px;
  padding-top: 2px;
  font-size: 1.2em ;
  text-align: left;
}
.h4Centre{
  margin: 15px;
  padding-top: 2px;
  font-size: 1.5em ;
}

.white{
  color: white;
}
.red{
  color: red;
}
.grey-text{
  color: darkgray;
}
.blue{
  color: blue;
}
.yellow{
  color: yellow;
}
.black{
  color: black;
}

.centred{
  align-items: center;
  justify-items: center;
  margin-top: 10px;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;  
}
.left{
  justify-self: start;
  margin-left: 10px;
}
.right{
  text-align: right;
}
img{
  float : left;
}

.error-text {
  text-align: center;
  color: red;
  font-weight: bold;
  padding: 8px;
}
.message-general {
  text-align: center;
  color: green;
  font-weight: bold;
}

/* ************************ MEDIA SCREENS ************************************ */
/* tablets */
@media screen and (max-width: 876px) {

/* .foot-wrapper{
  display: block;
} */
.main-banner {
    margin-top: 160px;
  }
  
  /* .nav-wrapper{
    height: 160px;
  }  */
};

@media screen and (max-width: 650px) {
  /* .navLink {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }   */
  /* .linksTo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 3;
    justify-content: space-around;
    align-items: center;
  } */
  /* .logo{
    margin-top: 5%;
    margin-left: 5%;
    justify-content: center;
  } */
 
  /* nav a.active{
    color: #013220;   
    border-bottom: 0;
  } */

  .main-banner {
      margin-top: 170px;
  }

  .input-field{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
  }

  .input-field input {
    width: 100%;
  }

  .filler{
    flex: 0.5;
  }

  /* .nav-wrapper{
    height: 160px;
  }  */

};



/* mobiles */
@media screen and (max-width: 480px) {
/* .nav-wrapper{
    height: 300px;
}   */
.main-banner {
    margin-top: 800px;
}

.filler{
    display: none;
  }
/* .foot-wrapper{
  display: block;
} */
};