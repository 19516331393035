.formData {
    margin: 20px auto;
    width: 85%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.radioTop{
    margin-top: 20px;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    color: #000;
    font-weight: bold;
}

.radioBottom{
    margin-top: 15px;
    margin-bottom: 25px;
}

.radioSpace{
    margin-left: 30px;
    padding-top: 16px;
}

/* New class to put a label at the left in black */
.label-left-black{
    color:black;
    font-weight: bold;
    text-align: left;
}

@media (min-width: 600px) {
    .formData {
        width: 500px;
    }
}