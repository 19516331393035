.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
    color: black;
}

.LabelLeft {
    font-weight: bold;
    justify-content: left;
    margin-bottom: 8px;
    color: white;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}

@media screen and (max-width: 400px) {
    .Input {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    } 
};