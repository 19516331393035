.flex90 {
    flex: 90;
    width: 100%;
    color: black;
}

.flex10 {
    flex: 10;
    width: 100%;
}

.alignRHS{
    margin-right: 6px;
    text-align: right;
}

.survey-field {
    display: flex;
    justify-content: flex-end;
    margin: 15px;
}

.survey-field-NIOH {
    /* display: flex; */
    width: 100px;
    justify-content: center;
    margin: 15px;
}

.NIOH-columns {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
  }

.temp {
    min-width: 20px;
    max-width: 30px;
}
