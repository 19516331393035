/* NavBar */

nav li{
    list-style-type: none;
    margin-top: 5px;
    text-align: center;
}

nav a{
    text-decoration: none;
    text-align: center;
    color: #fff;
    padding: 6px;
    margin: 0 auto;
    flex-basis: 20%;
    font-size: 20px;
}

nav a.active{
    border: 2px ridge white;
    transition: all ease 0.1s;
    background-color:var(--blue-1);
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
}

.nav-wrapper{
    display: flex;
    background: var(--green-1);
    width: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
}

.navLink {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-right: 20px;
    height: 50px;
}

.nav-menu {
    display: none;
}

.logo{
    flex: 1;
    align-items: center;
}

.nav-logo{
    margin-left:30px;
    margin-top: 10px;
}

.nav-routes {
    flex: 4;
}

.routes {
    flex-flow: column;
    justify-content: space-around;
    margin: 6px;
}

.button {
    background-color: #008000;
    color: white;
    padding: 6px;
    font-size: 20px;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    border-radius: 8px;
}

/* Dropdown Button */
.dropbtn {
    background-color: #008000;
    color: white;
    padding: 6px;
    font-size: 20px;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    border-radius: 8px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 40px;
    margin-right: auto;
    overflow: hidden;
    /* top: 0;
    left: 0; */
    /* position: relative; */
}

.dropbtn a {
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    border: 2px ridge white;
    background-color: #0E837F;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: fixed;
    /* position: absolute; */
    top: 0;
    margin-top: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
    background-color: #239555;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 20;
    border-radius: 8px;
}

/* Links inside the dropdown */
.dropdown-content a {
    text-align: center;
    color: white;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
.dropdown-content a:hover {
      background-color: #0E837F; 
      border-radius: 8px; 
      border: 2px ridge white;
      /* filter: brightness(1.2); */
}
  
  /* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #0E837F; 
    border: 2px ridge white;
}

@media screen and (max-width: 1030px) {
    .navLink {
        height: 80px;
    }
}  

@media screen and (max-width: 750px) {
    .navLink {
        height: 140px;
    }
    .nav-menu {
        display: none;
        height: 40px;
        width: 40px;
        background: url(/assets/menu.png) no-repeat 98% center;
        background-color: #239555;
        cursor: pointer;
    }
}  

@media screen and (max-width: 480px) {
    .navLink {
        height: 160px;
    }

/* 
    .wrapper {
        width: 100%;
        padding: 0;
    }   

    nav ul{
        overflow: hidden;
        background: #0E837F;
       // height: 0;

    }

    nav ul.open{
        height: auto;      
    }
    nav ul li{
        float: none;
        text-align: left;
        width: 100%;
        margin: 0;
    }

    nav ul li a{
        color: white;
        padding: 10px;
        border-bottom: 1px solid  #239555;
        display: block;
        margin: 0;
    } */

    .menu {
        /* height: 80%; */
        display: none;
        padding: 8px;
        background-color: #239555;
    }

    .menu img {
        height: 40px;
    }

    .sidedraw {
        position: fixed;
        width: 70%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: white;
        padding: 32px 16px;
        box-sizing: border-box;
        transition: transform 0.3s ease-out;
    }

    .open {
        transform: translateX(0);
    }
    .close {
        transform: translateX(-100%);
    }
}  